<ng-container *puLet="vm$ | async as vm">
  <pu-avatar
    class="ta-user-profile__avatar"
    size="36"
    [image]="vm.userProfile.picture"
    [nameFirstLetter]="vm.userProfile.email && vm.userProfile.email[0]"
  />

  <div class="ta-user-profile__content">
    <h3 class="ta-user-profile__name">
      {{ vm.userProfile.fullName }}
    </h3>

    <p class="ta-user-profile__position">
      {{ vm.userProfile.position }}
    </p>
  </div>

  <button
    class="ta-user-profile__btn-edit"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    [puPopover]="popover"
    [position]="['bl', 'br']"
  >
    <pu-icon iconName="edit"/>

    <ng-template #popover>
      <div class="ta-user-profile__popover">
        <button
          class="md:pu-button_m"
          puButton
          uiType="tertiary"
          size="l"
          (click)="logout()"
        >
          {{ 'account.logout' | translate }}
        </button>

        <button
          class="md:pu-button_m"
          puButton
          uiType="secondary"
          size="l"
          (click)="remove2fa()"
        >
          {{ 'account.remove2fa' | translate }}
        </button>
      </div>
    </ng-template>
  </button>
</ng-container>
